<template>
    <div class="viewContainer winecard">
        <b-row>
            <b-col>
                <h1>{{$t('winecard.title')}}</h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="3">
                <div class="winecardPlaceholder">
                    <div>
                        <p>
                            {{$t('winecard.imagePlaceholder')}}
                        </p>
                    </div>    
                </div>
            </b-col>
             <b-col cols="6" class="activateWinecard">
                <h2>{{$t('winecard.headline')}}</h2>
                <p>
                    {{$t('winecard.text')}}
                </p>
                <div class="spacer m"></div>
                <b-form-textarea
                    id="textarea"
                    v-model="requestText"
                    :placeholder="$t('winecard.textareaPlaceholder')"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
                <div class="spacer l"></div>
                <b-button @click="sendRequest" variant="primary">{{$t('general.buttons.submit')}}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import DataService from '@/services/StrapiService'
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            requestText: ""
        }
    },
    methods: {
        sendRequest() {
            DataService.sendMail(this.requestText).then(() => {
                this.$store.commit('setAlertText',{ text: this.$t('winecard.messageSuccess'), variant: 'info'})
                this.requestText = ""
            }).catch((err) => {
                this.$store.commit('setAlertText',{ text: this.$t('winecard.messageError'), variant: 'danger'})
                console.log(err)
            })
        }
    }
}
</script>